import React, { Component } from 'react';
import '../../Style.css';
import AutoFitImage from 'react-image-autofit-frame';
import {withRouter} from 'react-router';

export const Home = () => (


    
      <div>
      <h1> </h1>
      </div> 
   )
   




export default withRouter(Home);
; 