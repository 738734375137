import React, {Component} from 'react';
import '../../Style.css';
import { BrowserRouter, Link } from 'react-router-dom';


class about extends Component{

    render () {
  
    return (
      <BrowserRouter>
      
    <div className = "AboutText">
       <div className="AboutParagraph">
     <p className="About">Jon Friedberg is a Programmer with seven years of expertise, including three years as a Full-Stack Developer at ISHK, and an Illustrator with over 20 years of experience. He holds a Bachelor's Degree in Audio Production, and has pursued additional Education Courses – including a Certificate in Animation, and a Certificate of Completion in General Assembly's Software Engineering Program – back in July, 2019. For the past few years, his studies have been focused in DevOps Engineering – particularly in honing his skills with both AWS and Docker. He has contnued to improve his abilities as Full-Stack Developer, mainly focusing on React and Node.js Applications. </p>
     </div>
     <div className="SkillsAndSoftware">
     <h1 className="certifications">Certifications:</h1>
     <h2 className="certifications">-Python for Data Science, AI & Development (2023)</h2>
     <h2 className="certifications">-Certificate of Completion in Software Engineering (2019)</h2>
     
       <h2 className= "SkillsTitle">Skills</h2>
     <p className="SkillsParagraphText">
     <h2>Advanced:</h2>Linux (Mac OS), Linux, Ubuntu, AWS, Apache, Nginx, SEO, PHP, Materialize UI, Bootstrap, React, PostgreSQL, Nodejs, Express, MongoDB, Terminal Commands, Website Migrations (DNS Records, Domain Registrar), HTML, CSS, Javascript, Git commands, Algebra, Trigonometry, Calculus, Discrete Mathematics, Computer Science, drawing, painting, 3-D Modeling, UV Unwrapping, Photography, Stereo and 5.1 recording, Mixing & Editing Audio, ADR, SFX (Foley, Soft FX, Hard FX)
     <h2>Moderate:</h2>Ruby on Rails, Angular, Java, Ruby

     <h2>Intermediate:</h2> C++
    </p>
    
      <h2 className= "SoftwareTitle">Software</h2>
    <p className="SoftwareParagraphText">
    <h2>Advanced:</h2>phpMyAdmin, Gutenberg, Wordpress, Mailchimp, Text Editors, Maya, Z-Brush, Substance Painter, Final Cut Pro, Adobe Suite: Photoshop; Premiere; Audition, ProTools, Illustrator, After Effects, In Design
    <h2>Moderate:</h2>Docker, Eclipse (Java)
    </p>  
    </div>
    </div>
   
    </BrowserRouter>
    )}
    }
export default about;