import React from 'react';
import styled from 'styled-components';

function Footer() {
    const resumeUrl = process.env.PUBLIC_URL + '/Jon_Friedberg_Resume.pdf'; // This references the PDF file in the public folder

    const downloadResume = () => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = resumeUrl;
        link.setAttribute('download', 'Jon_Friedberg_Resume.pdf'); // Specify the filename for the downloaded file

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event of the link
        link.click();

        // Clean up: remove the link from the DOM
        document.body.removeChild(link);
    };
    return (
        <FooterContainer className="main-footer">
        <div className= "footer-middle">
        <div className="container"> 
        <div className="footer-row">
            {/* Column 1 */}
        <div className="col-md-3 col-sm-3">
        
        <ul className="list-unstyled ">
            <li><a href="https://github.com/friedbergjon">Github</a></li>
        </ul> 
        </div>
         {/* Column 2 */}
         <div className="col-md-3 col-sm-3">

        <ul className="list-unstyled ">
        <li><a href="https://www.linkedin.com/in/jonfriedberg/">LinkedIn</a></li>
        </ul>
        </div>
         {/* Column 4 */}
        <div className="col-md-3 col-sm-3">

        <ul className="list-unstyled ">
         <li><a href="#" onClick={downloadResume}>Resume</a></li>
        </ul>   
        </div>
        </div>
        {/* Footer Bottom */}
        <div className= "footer-bottom"> 
         </div>
        </div>
        <ul className="contact-info">
            <li>friedberg_jon@hotmail.com</li>
            <li>(618) 631-5490</li>
        </ul> 
        </div>
        </ FooterContainer> 
    )
}

export default Footer;

const FooterContainer = styled.footer
`
.footer-middle { background: var(--mainDark);
    padding-top: 1.5rem;
    color: var(--mainWhite);

}

.footer-bottom {
    padding-top: 3rem;
    padding-bottom: 2rem; 

}

ul li a{
    color: var(--mainGrey);
}

ul li a:hover{
    color: var( --mainWhite);
}
`; 